import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useRef, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Client, IMessage } from '@stomp/stompjs'
import { useTranslation } from 'react-i18next'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import SockJS from 'sockjs-client'

import { fetchCooperatingWholesalersData } from 'shared/services/fetchCooperationWholesalers'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import mergeObjects from 'shared/utils/helpers/mergeObjects'
import { useProfileQuery } from 'shared/store/useProfile'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'
import { httpClient } from 'shared/lib'

import { ProductDetails } from './types'
import { fetchProductDetails } from './services'
import NoCooperationTable from './components/NoCooperationTable'
import NoProductTable from './components/NoProductTable'
import ProductInfo from './components/ProductInfo'
import MainTable from './components/MainTable'
import { pl, en, de } from './locales'

const SOCKET_URL = `${process.env.REACT_APP_API_URL}/websockets`

export default function ProductDetailsView() {
   const { i18n, t } = useTranslation('PRODUCT_DETAILS')
   i18n.addResourceBundle('pl-PL', 'PRODUCT_DETAILS', pl)
   i18n.addResourceBundle('en-EN', 'PRODUCT_DETAILS', en)
   i18n.addResourceBundle('de-DE', 'PRODUCT_DETAILS', de)
   const [isData, setIsData] = useState(false)
   const [productNotFound, setProductNotFound] = useState(false)
   const [productDetails, setProductDetails] = useState<ProductDetails>()
   const { user } = useLogin()
   const [data, setData] = useState<FetchCooperationDataResult>({
      availableWholesalers: [],
      unAvailableWholesalers: [],
   })
   const { data: userProfileData } = useProfileQuery()
   const websocket = useRef<Client>()

   const [searchParams] = useSearchParams()
   const navigate = useNavigate()
   const productID = searchParams.get('uuid')

   function publishWithPromise(destination: string, headers: Record<string, string>, body: string): Promise<void> {
      return new Promise((resolve) => {
         if (websocket.current) {
            websocket.current.publish({ destination, headers, body })
         }
         resolve()
      })
   }

   async function handleWebSocketMessage(msg: IMessage): Promise<void> {
      setData((prevData) => {
         const newData = {
            ...prevData,
            availableWholesalers: prevData.availableWholesalers.map((item) => {
               if (msg.body) {
                  const jsonBody = JSON.parse(msg.body) as CooperatingWholesaler
                  if (item.wholesaleUuid === jsonBody.wholesaleUuid) {
                     const updatedItem = mergeObjects(item, jsonBody) as CooperatingWholesaler
                     return updatedItem
                  }
               }
               return item
            }),
         }
         return newData
      })
   }

   async function onConnected(): Promise<void> {
      if (websocket.current) {
         await publishWithPromise(
            `/core/api/ws/product/availability-in-cooperating/${userProfileData?.uuid}${productID}`,
            {
               'X-Authorization': `Bearer ${window.localStorage.getItem('ACCESS_TOKEN')}`,
            },
            JSON.stringify({ productUuid: productID })
         )

         await new Promise<void>((resolve) => {
            if (websocket.current) {
               websocket.current.subscribe(
                  `/product/availability-in-cooperating/${userProfileData?.uuid}${productID}`,
                  (msg) => {
                     handleWebSocketMessage(msg)
                     resolve()
                  }
               )
            }
         })
      }
   }

   async function init(productUuid: string) {
      await fetchProductDetails(productUuid)
         .then((res) => setProductDetails(res))
         .catch((err) => {
            if (err.message === 'product not found') {
               setProductNotFound(true)
            }
         })
      await fetchCooperatingWholesalersData(productUuid).then((res) => {
         setData({
            ...data,
            availableWholesalers: res.availableWholesalers,
            unAvailableWholesalers: res.unAvailableWholesalers,
         })
         setIsData(true)
      })
   }

   useEffect(() => {
      if (!productID) {
         navigate('/dashboard/search')
         return
      }
      if (userProfileData) {
         init(productID)
      }
   }, [productID, userProfileData, httpClient])

   useEffect(() => {
      if (isData) {
         websocket.current = new Client({
            webSocketFactory: () => new SockJS(SOCKET_URL),
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: onConnected,
            // onDisconnect: () => console.log(0, 'WS IS DISCONNECTED'),
            connectHeaders: {
               'X-Authorization': `Bearer ${window.localStorage.getItem('ACCESS_TOKEN')}`,
            },
            // onStompError: (err: IFrame) => console.log(0, 'STOMP ERROR', err),
            // debug: (status: string) => console.log(0, 'WS DEBUG STATUS', status),
         })
         websocket.current.activate()
      }
   }, [isData, userProfileData])

   useEffect(() => {
      return () => {
         if (websocket.current) {
            websocket.current.deactivate()
         }
      }
   }, [])

   return (
      <Box
         sx={{
            display: 'flex',
            flexDirection: 'column',
         }}
      >
         {productNotFound ? (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
               <Typography variant="h4" color="primary">
                  {t('productNotFound')}
               </Typography>
            </div>
         ) : (
            <>
               <ProductInfo data={productDetails} />
               {isData ? (
                  <MainTable data={data.availableWholesalers} details={productDetails} productID={productID} />
               ) : (
                  <Box sx={{ width: '100%', textAlign: 'center', mt: 1 }}>
                     <CircularProgress />
                  </Box>
               )}
               {!user.roles.includes(UserRoles.DEMO) && (
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20, width: '100%' }}>
                     <Badge badgeContent="BETA" color="error">
                        <ContainedButton
                           onClick={() =>
                              navigate(
                                 `/dashboard/search?value=${productID}&index=${productDetails?.index}&searchType=SUBSTITUTES`
                              )
                           }
                           variant="contained"
                        >
                           <SwapHorizontalCircleOutlinedIcon sx={{ color: 'white', marginRight: 2 }} />
                           {t('PRODUCT_DETAILS:tables.showSubstitutesButton')}
                        </ContainedButton>
                     </Badge>
                  </div>
               )}
               {isData && <NoCooperationTable locationID={userProfileData?.locationUuid} productID={productID} />}
               <NoProductTable data={data.unAvailableWholesalers} productID={productID} />
            </>
         )}
      </Box>
   )
}
