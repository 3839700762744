/* eslint-disable no-nested-ternary */
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CircularProgress from '@mui/material/CircularProgress'
import TableSortLabel from '@mui/material/TableSortLabel'
import TableFooter from '@mui/material/TableFooter'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { useNotificationContext } from 'shared/context/Notifications'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import {
   StyledTable,
   StyledSpan,
   StyledTableCell,
   ProductTableRow,
   ButtonWrapper,
   StyledBox,
   PayBox,
   StyledContentTableCell,
   StyledAvailabilityDot,
} from './styles'
import {
   DuplicatedProduct,
   FetchProductsResult,
   sortDirectionType,
   Product,
   SendDuplicatesPayload,
   FetchProductsPayload,
} from './types'
import { pl, en, de } from './locales'
import { fetchProducts, getSubstitutesService, sendDuplicates } from './services'
import MergeProductsModal from './components/MergeProductsModal'
import DeleteModal from './components/DeleteModal'
import ScoreDetailsModal from './components/ScoreDetailsModal'

const columnNames = [
   { name: 'index', translateLabel: 'partIndex' },
   { name: 'name', translateLabel: 'productName' },
   { name: 'producer', translateLabel: 'producer' },
   { name: 'meanPrice', translateLabel: 'meanPrice' },
   { name: 'availability', translateLabel: 'availability' },
]

export default function ProductsList() {
   const { t, i18n } = useTranslation(['TRANSLATION', 'PRODUCTS_LIST'])
   i18n.addResourceBundle('pl-PL', 'PRODUCTS_LIST', pl)
   i18n.addResourceBundle('en-EN', 'PRODUCTS_LIST', en)
   i18n.addResourceBundle('de-DE', 'PRODUCTS_LIST', de)
   const navigate = useNavigate()
   const [searchParams] = useSearchParams()
   const { addNotification } = useNotificationContext()
   const { user } = useLogin()
   const location = useLocation()
   const [duplicatedProducts, setDuplicatedProducts] = useState<DuplicatedProduct[]>([])
   const [sortDirection, setSortDirection] = useState<sortDirectionType>('asc')
   const [isSendingDuplicates, setIsSendingDuplicates] = useState(false)
   const [searchError, setSearchError] = useState<string>()
   const [deleteModal, setDeleteModal] = useState<string>()
   const [data, setData] = useState<FetchProductsResult>()
   const [sortedProducts, setSortedProducts] = useState<PropType<FetchProductsResult, 'products'>>([])
   const [sortBy, setSortBy] = useState<Exclude<keyof Product, 'wholesaleName' | 'scoreComponents'>>()
   const [openScoreDetails, setOpenScoreDetails] = useState<PropType<Product, 'scoreComponents'>>()
   const [itemsPerPage, setItemsPerPage] = useState(25)
   const [isLoading, setIsLoading] = useState(false)
   const [openModal, setOpenModal] = useState(false)
   const [activePage, setActivePage] = useState(0)

   const searchValue = searchParams.get('searchType') !== 'SUBSTITUTES' ? searchParams.get('value') || '' : ''

   function handleItemsPerPage(event: SelectChangeEvent<number>) {
      setActivePage(0)
      window.localStorage.setItem('itemsPerPage', event.target.value.toString())
      setItemsPerPage(event.target.value as number)
   }

   function foundResultsString(resultsCount: number) {
      if (resultsCount === 0) return 'notFound'
      return ''
   }

   function returnSearchPhrase() {
      if (searchParams.get('searchType')) {
         switch (searchParams.get('searchType')) {
            case 'OIL':
               return `${searchParams.get('producer') || []} ${searchParams.get('sae') || ''} ${
                  searchParams.get('capacity') || ''
               }${searchParams.get('standard') || ''}`
            case 'INDEX':
            case 'OE':
            case 'EXTENSION':
               return searchParams.get('value')
            case 'SUBSTITUTES':
               return `${searchParams.get('index')} - ${t('PRODUCTS_LIST:substitutes')}`
            default:
               return ''
         }
      }
      return ''
   }

   function handleOnClick(productUuid: string) {
      navigate({
         pathname: '/dashboard/product',
         search: createSearchParams({
            uuid: productUuid,
         }).toString(),
      })
   }

   function handleScrollClick(productUuid: string) {
      const urlStart = window.location.href.split('/')[0]
      const url = `${urlStart}/dashboard/product?uuid=${productUuid}`
      return window.open(url, '_blank')
   }

   function handleDuplicates(productUuid: string, productIndex: string, producerName: string, wholesaleCount: number) {
      if (duplicatedProducts.some((item) => item.uuid === productUuid)) {
         const newDuplicatedProducts: DuplicatedProduct[] = duplicatedProducts.filter(
            (item) => item.uuid !== productUuid
         )
         setDuplicatedProducts(newDuplicatedProducts)
      } else {
         const newDuplicatedProducts: DuplicatedProduct[] = [
            ...duplicatedProducts,
            { index: productIndex, uuid: productUuid, producer: producerName, wholesaleCount },
         ]

         setDuplicatedProducts(newDuplicatedProducts)
      }
   }

   function handleDuplicatesSend() {
      setIsSendingDuplicates(true)
      const payload: SendDuplicatesPayload = {
         products: duplicatedProducts,
      }
      sendDuplicates(payload)
         .then(() => {
            addNotification('duplicatesSend', 'success')
         })
         .catch((err) => {
            if (err) {
               addNotification('failedSave', 'error')
            }
         })
         .finally(() => {
            setIsSendingDuplicates(false)
            setDuplicatedProducts([])
         })
   }

   function handleSortingChange(sortName: Exclude<keyof Product, 'wholesaleName' | 'scoreComponents'>) {
      setSortBy(sortName)
      if (sortDirection === 'asc') {
         setSortDirection('desc')
      } else {
         setSortDirection('asc')
      }
   }

   function sortingType(a: string | number | boolean, b: string | number | boolean) {
      switch (sortDirection) {
         case 'desc':
            return a > b ? -1 : 1
         case 'asc':
            return a > b ? 1 : -1
         default:
            return 0
      }
   }
   function returnProductName(productName: string, productIndex: string) {
      if (productName) {
         if (productName.length > 100) {
            return `${productName.slice(0, 100)}...`
         }
         return productName
      }
      return productIndex
   }

   function returnAvailabilityDot(availability: number) {
      switch (true) {
         case availability === 0:
            return <StyledAvailabilityDot color="red" />
         case availability === 1:
            return <StyledAvailabilityDot color="orange" />
         case availability === -1:
            return <StyledAvailabilityDot color="gray" />
         case availability > 1:
            return <StyledAvailabilityDot color="green" />
         default:
            return null
      }
   }

   useEffect(() => {
      setIsLoading(true)
      const isFetching = true
      setSortBy(undefined)
      setDuplicatedProducts([])
      const getSearchType = searchParams.get('searchType') as PropType<FetchProductsPayload, 'searchType'>
      const filters: PropType<FetchProductsPayload, 'filters'> = {}
      const sourceParams: PropType<FetchProductsPayload, 'sourceParams'> = {}
      searchParams.forEach((value, key) => {
         if ((key === 'sae' || key === 'standard' || key === 'capacity') && value !== '') {
            filters[key] = value
         }
      })

      searchParams.forEach((value, key) => {
         if ((key === 'vin' || key === 'category' || key === 'model' || key === 'carBrand') && value !== '') {
            sourceParams[key] = value
         }
      })

      const fetchProductsData = () => {
         fetchProducts({
            searchType: getSearchType || 'INDEX',
            searchPhrase: searchParams.get('value') || null,
            producers: searchParams.get('producer') ? [`${searchParams.get('producer')}`] : [],
            source: searchParams.get('catalogName') || null,
            sourceParams,
            filters,
         })
            .then((res) => {
               if (isFetching) {
                  setData(res)
                  setSearchError(undefined)
                  setIsLoading(false)
                  if (res.products.length === 1) {
                     if (location.state) {
                        if (location.state.indexes) {
                           navigate(
                              {
                                 pathname: '/dashboard/product',
                                 search: createSearchParams({
                                    uuid: res.products[0].uuid,
                                 }).toString(),
                              },
                              {
                                 state: {
                                    indexes: location.state.indexes,
                                    activeIndex: location.state.activeIndex || 0,
                                 },
                              }
                           )
                        }
                     } else {
                        navigate({
                           pathname: '/dashboard/product',
                           search: createSearchParams({
                              uuid: res.products[0].uuid,
                           }).toString(),
                        })
                     }
                  }
               }
            })
            .finally(() => {
               if (isFetching) {
                  setIsLoading(false)
               }
            })
      }

      const getSubstitutesData = () => {
         getSubstitutesService(searchParams.get('value') || '')
            .then((res) => {
               if (isFetching) {
                  setData(res)
                  setSearchError(undefined)
               }
            })
            .catch(() => {
               setSearchError('500')
               if (user.roles.includes(UserRoles.INTERNAL)) {
                  setData({ resultsCount: 0, products: [], searchSession: '' })
               }
            })
            .finally(() => {
               if (isFetching) {
                  setIsLoading(false)
               }
            })
      }

      switch (getSearchType) {
         case 'OIL':
         case 'EXTENSION':
         case 'INDEX':
         case 'OE':
            fetchProductsData()
            break
         case 'SUBSTITUTES':
            getSubstitutesData()
            break
         default:
            break
      }

      setActivePage(0)
      if (window.localStorage.getItem('itemsPerPage')) {
         setItemsPerPage(Number(window.localStorage.getItem('itemsPerPage')))
      } else {
         setItemsPerPage(25)
      }
   }, [searchValue, searchParams, navigate])

   useEffect(() => {
      if (data) {
         const tempSortedProducts = [...data.products]
            .sort((a, b) => (sortBy ? sortingType(a[sortBy], b[sortBy]) : 0))
            .slice(itemsPerPage * activePage, itemsPerPage * activePage + itemsPerPage)

         setSortedProducts([...tempSortedProducts])
      }
   }, [sortBy, sortDirection, itemsPerPage, activePage])

   useEffect(() => {
      if (data) {
         setSortedProducts(
            [...data.products].slice(itemsPerPage * activePage, itemsPerPage * activePage + itemsPerPage)
         )
      }
   }, [data])

   return (
      <StyledBox>
         {data && sortedProducts && !isLoading ? (
            user.roles.includes(UserRoles.SUBSCRIPTION) || user.roles.includes(UserRoles.DEMO) ? (
               <div>
                  <div
                     style={{
                        width: '80%',
                        margin: '0 auto',
                        display: 'flex',
                        flexDirection: data.resultsCount >= 250 || data.resultsCount === 0 ? 'column' : 'row',
                        gap: 5,
                     }}
                  >
                     <Typography variant="body1" color="text.secondary">
                        {t('PRODUCTS_LIST:searchedPhrase')}
                        <StyledSpan>{returnSearchPhrase()}</StyledSpan>
                     </Typography>
                     {data.resultsCount === 0 || data.resultsCount === 250 ? (
                        <Typography variant="h6" sx={{ color: 'red', textTransform: 'upperCase', margin: '0 auto' }}>
                           {t(`PRODUCTS_LIST:${foundResultsString(data.resultsCount)}`)}
                        </Typography>
                     ) : (
                        <Typography variant="body1" sx={{ color: 'grayText' }}>
                           {t('PRODUCTS_LIST:found')} <StyledSpan> {data ? data.resultsCount : 0} </StyledSpan>
                        </Typography>
                     )}
                     {/* to remove, temp for Kuba */}
                     {searchError && user.roles.includes(UserRoles.DATA_MANAGER) && (
                        <Typography variant="h4" color="red" sx={{ margin: '0 auto' }}>
                           500
                        </Typography>
                     )}
                  </div>
                  {sortedProducts.length > 0 && (
                     <>
                        <StyledTable>
                           <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                              <TableRow>
                                 {columnNames.map((column) => (
                                    <StyledTableCell key={column.name}>
                                       <TableSortLabel
                                          onClick={() =>
                                             handleSortingChange(
                                                column.name as Exclude<
                                                   keyof Product,
                                                   'wholesaleName' | 'scoreComponents'
                                                >
                                             )
                                          }
                                          active={sortBy === column.name}
                                          direction={sortDirection}
                                       >
                                          {t(`TRANSLATION:columnNames.${column.translateLabel}`)}
                                       </TableSortLabel>
                                    </StyledTableCell>
                                 ))}
                                 <StyledTableCell>
                                    <Tooltip title={t('PRODUCTS_LIST:duplicatedTooltip') || ''}>
                                       <span>{t('PRODUCTS_LIST:duplicated')}</span>
                                    </Tooltip>
                                 </StyledTableCell>
                                 {user.roles.includes(UserRoles.DATA_MANAGER) && <StyledTableCell />}
                                 {user.roles.includes(UserRoles.DATA_MANAGER) && (
                                    <StyledTableCell>Podpięta hurtownia </StyledTableCell>
                                 )}
                                 {(user.roles.includes(UserRoles.DATA_MANAGER) ||
                                    user.roles.includes(UserRoles.INTERNAL)) && (
                                    <StyledTableCell> Score </StyledTableCell>
                                 )}
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {sortedProducts.map((product) => (
                                 <ProductTableRow
                                    sx={{ backgroundColor: 'rgba(255,255,255,0.7)' }}
                                    onClick={() => handleOnClick(product.uuid)}
                                    onMouseDown={(e) => {
                                       if (e.button === 1) handleScrollClick(product.uuid)
                                    }}
                                    key={product.uuid}
                                 >
                                    <StyledContentTableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                                       {product.index.length > 30 ? `${product.index.slice(0, 30)}...` : product.index}
                                       {product.wholesaleCount < 2 ? (
                                          <Tooltip
                                             sx={{ color: 'red' }}
                                             title={t('PRODUCTS_LIST:unmappedTooltip') || ''}
                                          >
                                             <PriorityHighRoundedIcon style={{ marginBottom: -5, fontSize: 20 }} />
                                          </Tooltip>
                                       ) : null}
                                    </StyledContentTableCell>
                                    <StyledContentTableCell
                                       sx={{ textAlign: 'center', color: 'grayText', width: '50%' }}
                                    >
                                       {returnProductName(product.name, product.index)}
                                    </StyledContentTableCell>
                                    <StyledContentTableCell>
                                       <Tooltip title={product.producer}>
                                          <img
                                             style={{ verticalAlign: 'middle', height: 20, width: 'auto' }}
                                             src={`https://files.motorro.eu/img/?name=${product.producerNameWithoutSpecialChars.toLowerCase()}`}
                                             alt={product.producer}
                                          />
                                       </Tooltip>
                                    </StyledContentTableCell>
                                    <StyledContentTableCell>
                                       {product.meanPrice !== 0 ? product.formattedMeanPrice : '-'}
                                    </StyledContentTableCell>
                                    <StyledContentTableCell>
                                       {returnAvailabilityDot(product.availability)}
                                    </StyledContentTableCell>
                                    <StyledContentTableCell onClick={(e) => e.stopPropagation()}>
                                       <Checkbox
                                          color="primary"
                                          checked={!!duplicatedProducts.find((item) => item.uuid === product.uuid)}
                                          onChange={() =>
                                             handleDuplicates(
                                                product.uuid,
                                                product.index,
                                                product.producer,
                                                product.wholesaleCount
                                             )
                                          }
                                       />
                                    </StyledContentTableCell>
                                    {user.roles.includes(UserRoles.DATA_MANAGER) && (
                                       <StyledContentTableCell>
                                          <Button
                                             variant="outlined"
                                             color="error"
                                             onClick={(e) => {
                                                e.stopPropagation()
                                                e.preventDefault()
                                                setDeleteModal(product.uuid)
                                             }}
                                          >
                                             Usuń
                                          </Button>
                                       </StyledContentTableCell>
                                    )}
                                    {user.roles.includes(UserRoles.DATA_MANAGER) && (
                                       <StyledContentTableCell>
                                          <Typography variant="body2" fontWeight="bold">
                                             {product.wholesaleCount <= 1 && (product.wholesaleName || 'BRAK')}
                                          </Typography>
                                       </StyledContentTableCell>
                                    )}
                                    {(user.roles.includes(UserRoles.DATA_MANAGER) ||
                                       user.roles.includes(UserRoles.INTERNAL)) && (
                                       <StyledContentTableCell
                                          onClick={(e) => {
                                             e.stopPropagation()
                                             e.preventDefault()
                                             setOpenScoreDetails(product.scoreComponents)
                                          }}
                                       >
                                          <Typography variant="body2" fontWeight="bold">
                                             {product.customScore}
                                          </Typography>
                                       </StyledContentTableCell>
                                    )}
                                 </ProductTableRow>
                              ))}
                           </TableBody>
                           <TableFooter sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                              <TableRow>
                                 <StyledContentTableCell
                                    sx={{ textAlign: 'right', paddingRight: 7 }}
                                    colSpan={user.roles.includes(UserRoles.DATA_MANAGER) ? 9 : 6}
                                 >
                                    {t('PRODUCTS_LIST:itemsPerPage')}
                                    <Select
                                       sx={{ mx: 1, color: 'grayText', fontSize: 14 }}
                                       variant="standard"
                                       disabled={data.products.length === 0}
                                       value={itemsPerPage}
                                       onChange={(e) => handleItemsPerPage(e)}
                                    >
                                       <MenuItem value={10}> 10 </MenuItem>
                                       <MenuItem value={25}> 25 </MenuItem>
                                       <MenuItem value={50}> 50 </MenuItem>
                                    </Select>
                                    {t('PRODUCTS_LIST:displayed', {
                                       displayed: `${itemsPerPage * activePage + 1} - ${
                                          itemsPerPage * activePage + itemsPerPage > data.products.length
                                             ? data.products.length
                                             : itemsPerPage * activePage + itemsPerPage
                                       }`,
                                       all: data.products.length,
                                    })}
                                    <IconButton
                                       disabled={data.products.length === 0 || activePage === 0}
                                       onClick={() => setActivePage((page) => page - 1)}
                                    >
                                       <ChevronLeftIcon />
                                    </IconButton>
                                    <IconButton
                                       disabled={
                                          data
                                             ? data.products.length === 0 ||
                                               activePage === Math.ceil(data.products.length / itemsPerPage) - 1
                                             : false
                                       }
                                       onClick={() => setActivePage((page) => page + 1)}
                                    >
                                       <ChevronRightIcon />
                                    </IconButton>
                                 </StyledContentTableCell>
                              </TableRow>
                           </TableFooter>
                        </StyledTable>
                        <ButtonWrapper>
                           {user.roles.includes(UserRoles.DATA_MANAGER) ? (
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                 <Button
                                    variant="contained"
                                    color="error"
                                    sx={{ borderRadius: 0.5 }}
                                    disabled={duplicatedProducts.length < 2 || isSendingDuplicates}
                                    onClick={() => setOpenModal(true)}
                                 >
                                    Połącz produkty
                                 </Button>
                                 <ContainedButton
                                    variant="contained"
                                    disabled={duplicatedProducts.length < 2 || isSendingDuplicates}
                                    onClick={handleDuplicatesSend}
                                 >
                                    {t('PRODUCTS_LIST:sendDuplicates')}
                                 </ContainedButton>
                              </Box>
                           ) : (
                              <ContainedButton
                                 variant="contained"
                                 disabled={duplicatedProducts.length < 2 || isSendingDuplicates}
                                 onClick={handleDuplicatesSend}
                              >
                                 {t('PRODUCTS_LIST:sendDuplicates')}
                              </ContainedButton>
                           )}
                        </ButtonWrapper>
                     </>
                  )}
               </div>
            ) : (
               <PayBox>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: 20 }}>
                     <Typography variant="h6" sx={{ mt: 1, color: 'red', textTransform: 'uppercase' }}>
                        {t('PRODUCTS_LIST:buySubscription')}
                     </Typography>
                     <ContainedButton onClick={() => navigate('/dashboard/payments')} variant="contained">
                        {t('PRODUCTS_LIST:payments')}
                     </ContainedButton>
                  </div>
               </PayBox>
            )
         ) : (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
               <CircularProgress color="primary" />
            </div>
         )}
         <MergeProductsModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            duplicatedProducts={duplicatedProducts}
         />
         <DeleteModal productUuid={deleteModal || ''} handleClose={() => setDeleteModal(undefined)} />
         {openScoreDetails && (
            <ScoreDetailsModal scoreComponents={openScoreDetails} handleClose={() => setOpenScoreDetails(undefined)} />
         )}
      </StyledBox>
   )
}
