import axios, { AxiosError } from 'axios'
import { isEqual } from 'lodash'
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import i18n from 'locales/i18n'

import { useNotificationContext } from 'shared/context/Notifications'

import {
   fetchWholesalers,
   fetchWholesalerDeliveryTimes,
   editWholesalerDeliveryTimes,
   deleteWholesaler,
   addWholesaler,
   askForCooperation,
   editWholesaler,
   resendWholesalerCoopMail,
} from './services'
import {
   AddWholesalerPayload,
   AskCooperationPayload,
   DeleteWholesalerPayload,
   EditDeliveryTimesPayload,
   StateType,
} from './types'

export default function useWholesalers(locationId?: string): UseQueryResult<StateType> {
   return useQuery(['wholesalers', 'list', locationId], () => fetchWholesalers(locationId), {
      initialData: [],
      enabled: !!locationId,
   })
}

export function useDeliveryTimesQuery(wholesalerUuid: string, locationUuid: string) {
   return useQuery(['wholesalers', 'times', wholesalerUuid, locationUuid], () =>
      fetchWholesalerDeliveryTimes(wholesalerUuid, locationUuid)
   )
}

export function useDeliveryTimesMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()
   return useMutation<
      Result & Pick<EditDeliveryTimesPayload, 'wholesaleUuid'>,
      AxiosError<BackendError>,
      EditDeliveryTimesPayload,
      unknown
   >(
      async (payload) => {
         const { status } = await editWholesalerDeliveryTimes(payload)
         return {
            status,
            wholesaleUuid: payload.wholesaleUuid,
         }
      },
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries(['wholesalers', 'times', data.wholesaleUuid])
            }
         },
         onSettled: (data, error) => {
            if (data?.status === 200) {
               addNotification('successSave', 'success')
            } else if (error) {
               addNotification('failedSave', 'error')
            }
         },
      }
   )
}

export function useDeleteCooperationMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<BackendError>, DeleteWholesalerPayload, unknown>(
      (payload) => deleteWholesaler(payload),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('wholesalers')
            }
         },
         onSettled: (data, error) => {
            if (data?.status === 204) {
               addNotification('successSave', 'success')
            } else if (error) {
               addNotification('failedSave', 'error')
            }
         },
      }
   )
}

export function useResendCooperationEmailMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<BackendError>, { locationUuid: string; cooperationUuid: string }, unknown>(
      ({ locationUuid, cooperationUuid }) => resendWholesalerCoopMail(locationUuid, cooperationUuid),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('wholesalers')
            }
         },
         onSettled: (data, error) => {
            if (data?.status === 204) {
               addNotification('successSave', 'success')
            } else if (error) {
               addNotification('failedSave', 'error')
            }
         },
      }
   )
}

export function useAddCooperationMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<
      Result,
      AxiosError<BackendError>,
      { locationUuid: string; payload: AddWholesalerPayload },
      unknown
   >(({ locationUuid, payload }) => addWholesaler(locationUuid, payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('wholesalers')
         }
      },
      onSettled: (data, error) => {
         if (data?.status === 204) {
            addNotification('successSave', 'success')
         } else if (error) {
            addNotification('failedSave', 'error')
         }
      },
   })
}

export function useEditCooperationMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<
      Result,
      Error | AxiosError<BackendError>,
      { locationUuid: string; cooperationUuid: string; payload: AddWholesalerPayload },
      unknown
   >(
      ({ locationUuid, cooperationUuid, payload }) => {
         const initialData = queryClient.getQueryData<StateType>(['wholesalers', 'list', locationUuid])

         const allWholesalers = initialData?.cooperating
            .concat(initialData.notCooperating.CLIENT_DATA)
            .concat(initialData.notCooperating.CLIENT_ONLY)
            .concat(initialData.notCooperating.COOPERATION_EMAIL)

         const wholesalerToChange = allWholesalers?.find(
            (wholesaler) => wholesaler.wholesaleUuid === payload.wholesaleUuid
         )
         const departmentUuids: string[] = []
         departmentUuids[0] =
            wholesalerToChange?.departments.find((item) => item.type === 'FIRST')?.departmentUuid || ''
         departmentUuids[1] =
            wholesalerToChange?.departments.find((item) => item.type === 'SECOND')?.departmentUuid || ''
         departmentUuids[2] =
            wholesalerToChange?.departments.find((item) => item.type === 'THIRD')?.departmentUuid || ''
         const initialPayload = wholesalerToChange
            ? {
                 additionalParameter: wholesalerToChange.additionalParameter,
                 deliveryDepartment: wholesalerToChange.deliveryDepartment,
                 departmentUuids,
                 locationUuid: wholesalerToChange.locationUuid,
                 login: wholesalerToChange.login,
                 orderDocumentType: wholesalerToChange.orderDocumentType,
                 password: wholesalerToChange.password,
                 wholesaleUuid: wholesalerToChange.wholesaleUuid,
                 minLogistics: wholesalerToChange.minLogistics,
                 cooperationUuid: wholesalerToChange.cooperationUuid,
              }
            : {}
         if (isEqual(initialPayload, payload)) {
            throw new Error('apiErrors.nothingChanged')
         } else {
            return editWholesaler(locationUuid, cooperationUuid, payload)
         }
      },
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('wholesalers')
               queryClient.invalidateQueries('minimums')
            }
         },
         onSettled: (data, error) => {
            if (data?.status === 204) {
               addNotification('successSave', 'success')
            } else if (error) {
               if (axios.isAxiosError(error)) {
                  addNotification('failedSave', 'error')
               } else {
                  addNotification(i18n.t(`TRANSLATION:apiErrors.${error.message}`), 'error')
               }
            }
         },
      }
   )
}

export function useAskForCoopMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, AxiosError<ErrorType>, { locationUuid: string; payload: AskCooperationPayload }, unknown>(
      ({ locationUuid, payload }) => askForCooperation(locationUuid, payload),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('wholesalers')
            }
         },
         onSettled: (data, error) => {
            if (data?.status === 204) {
               addNotification('coopMailSendSuccess', 'success')
            } else if (error) {
               if (error.message === 'cooperation.mail.alreadySent') {
                  addNotification('coopMailSendFailed', 'error')
               } else {
                  addNotification('failedSave', 'error')
               }
            }
         },
      }
   )
}
