import CircularProgress from '@mui/material/CircularProgress'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import { useQueryClient } from 'react-query'
import Dialog from '@mui/material/Dialog'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'

import { useSettingsMutation, useSettingsQuery } from 'shared/store/useSettings'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import swapUserLocationService from 'shared/services/swapUserLocation'
import { useNotificationContext } from 'shared/context/Notifications'
import { getRefreshToken } from 'shared/utils/helpers/refreshToken'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import { useLocationsQuery } from 'shared/store/useLocations'
import { useProfileQuery } from 'shared/store/useProfile'

interface Props {
   open: boolean
   handleClose: () => void
}

export default function SwapLocationDialog({ open, handleClose }: Props) {
   const { data, isFetched } = useLocationsQuery()
   const { data: userData } = useProfileQuery()
   const { data: settingsData } = useSettingsQuery()
   const { mutate, isLoading } = useSettingsMutation(true)

   const [locationToSwap, setLocationToSwap] = useState<string>()

   const { t } = useTranslation(['TRANSLATION', 'AUTHORIZED'])
   const { addNotification } = useNotificationContext()
   const queryClient = useQueryClient()

   function handleModalClose() {
      if (locationToSwap) {
         if (locationToSwap !== userData?.locationUuid && locationToSwap !== 'lastLocation') {
            swapUserLocationService(locationToSwap)
               .then((res) => {
                  if (res.status === 200) {
                     addNotification(t('AUTHORIZED:header.successLocationSwap'), 'success')
                     queryClient.invalidateQueries('userProfile')
                     queryClient.invalidateQueries('userStats')
                     invalidateCarts(queryClient)
                     getRefreshToken()
                     handleClose()
                  }
               })
               .catch((err) => {
                  if (err) {
                     addNotification(t('AUTHORIZED:header.failedLocationSwap'), 'error')
                  }
               })
         }
      }
      if (locationToSwap === 'lastLocation' && settingsData) {
         const payload: SettingsType = {
            ...settingsData,
            userSettings: {
               ...settingsData.userSettings,
               preferences: {
                  ...settingsData.userSettings.preferences,
                  language: settingsData.userSettings.preferences.language,
                  priceDifferences: settingsData.userSettings.preferences.priceDifferences === 'true',
                  buyNowOrdersGroup: settingsData.userSettings.preferences.buyNowOrdersGroup === 'true',
                  displayMinPopup: settingsData.userSettings.preferences.displayMinPopup === 'true',
                  dontShowLocationDialog: true,
               },
            },
         }
         mutate(payload)
      }
      handleClose()
   }

   useEffect(() => {
      if (userData) {
         setLocationToSwap(userData.locationUuid)
      }
   }, [userData])

   return (
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
         {isFetched && data && userData ? (
            <>
               <DialogTitle sx={{ textAlign: 'center' }}>
                  <Typography sx={{ fontSize: 20 }} color="primary">
                     {t('AUTHORIZED:swapLocationsDialog.title')}
                  </Typography>
               </DialogTitle>
               <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Select variant="standard" value={locationToSwap} onChange={(e) => setLocationToSwap(e.target.value)}>
                     <MenuItem value="lastLocation">{t('AUTHORIZED:swapLocationsDialog.lastLocation')}</MenuItem>
                     {data.locations.map((location) => (
                        <MenuItem key={location.locationSettings.uuid} value={location.locationSettings.uuid}>
                           {location.locationSettings.name}
                        </MenuItem>
                     ))}
                  </Select>
                  <Typography variant="body1" fontWeight="bold">
                     {t('AUTHORIZED:swapLocationsDialog.choice')}
                  </Typography>
               </DialogContent>
               <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
                  <ContainedButton disabled={isLoading} onClick={handleModalClose} variant="contained">
                     {t('TRANSLATION:save')}
                  </ContainedButton>
               </Box>
            </>
         ) : (
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minWidth: '350px',
                  minHeight: '50px',
               }}
            >
               <CircularProgress color="primary" />
            </Box>
         )}
      </Dialog>
   )
}
