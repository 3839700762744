import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { changeOrderNameService, getOrdersService } from './services'
import { SearchOrdersHistoryPayload, StateType } from './types'

async function getOrders(payload: SearchOrdersHistoryPayload) {
   const orders = await getOrdersService(payload.searchValue, payload.filter)

   return {
      orders: orders.data,
      searchValue: payload.searchValue || '',
      currentYear: orders.currentYear,
      selectedFilter: payload.filter || '',
      startingYear: orders.startingYear,
      availableFilters: orders.availableFilters,
   }
}

export function useOrdersHistory(): UseQueryResult<StateType> {
   return useQuery(['ordersHistory'], () => getOrders({ searchValue: '', filter: 'LAST_90_DAYS' }))
}

export function useSearchOrdersHistory() {
   const queryClient = useQueryClient()
   return useMutation<StateType, unknown, SearchOrdersHistoryPayload, unknown>((payload) => getOrders(payload), {
      onSuccess: (data) => {
         if (Array.isArray(data.orders)) {
            queryClient.setQueryData(['ordersHistory'], data)
         }
      },
   })
}

export function useChangeOrderNameMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, { orderUuid: string; orderName: string }, unknown>(
      ({ orderUuid, orderName }) => changeOrderNameService(orderName, orderUuid),
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('ordersHistory')
            }
         },
      }
   )
}
