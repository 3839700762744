import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'

import { Product } from '../../types'

interface Props {
   scoreComponents: PropType<Product, 'scoreComponents'>
   handleClose: () => void
}
export default function ScoreDetailsModal({ scoreComponents, handleClose }: Props) {
   return (
      <Dialog maxWidth="md" onClose={handleClose} open={!!scoreComponents}>
         <DialogTitle color="primary"> Szczegóły dotyczące score</DialogTitle>
         <DialogContent>
            <Table>
               <TableHead>
                  <TableRow>
                     <TableCell>Typ</TableCell>
                     <TableCell>Score</TableCell>
                     <TableCell>Wartośc</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {scoreComponents?.map((item) => (
                     <TableRow key={item.type}>
                        <TableCell>{item.type}</TableCell>
                        <TableCell>{item.score}</TableCell>
                        <TableCell>{item.value}</TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
               <Typography variant="h6" color="primary">
                  Bazowe (w każdym wyszukaniu):
               </Typography>
               <span>
                  <b>BASIC_OE_PRODUCT</b> - true jeśli producent produktu jest producentem OE
               </span>
               <span>
                  <b>BASIC_WHOLESALE_COUNT</b> - liczba hurtowni podpiętych do produktu
               </span>
               <span>
                  <b>BASIC_AVAILABILITY</b> - aktualna dostępność produktu liczona w statystykach.
               </span>
               <span>
                  <b>BASIC_SEARCH_COUNT</b> - liczba wejść na kartę produktu z ostatnich 30 dni
               </span>
               <span>
                  <b>BASIC_ORDER_COUNT</b> - liczba zamówień z ostatnich 30 dni, zawierających produkt
               </span>
               <span>
                  <b>BASIC_PRODUCER</b> - producent produktu, wartość to uuid producenta, punkty będą dodatnie jeśli
                  dany producent ma boosta
               </span>
               <Typography variant="h6" color="primary">
                  Główna wyszukiwarka (tyczy się też wtyczki i każdego miejsca, które z niej korzysta):
               </Typography>
               <span>
                  <b>FO</b> - fraza oryginalna wpisana w wyszukiwarkę
               </span>
               <span>
                  <b>FS - FO</b> sformatowana bez znaków specjalnych
               </span>
               <span>
                  <b>MAIN_INDEX</b> - informacja o dopasowaniu indeksu produktu do FO
               </span>
               <span>są 4 opcje:</span>
               <span>
                  <b>EXACT</b> (indeks 1:1 z frazą),
               </span>
               <span>
                  <b>STARTS_WITH</b> (indeks zaczyna się od frazy)
               </span>
               <span>
                  <b>CONTAINS</b> (indeks zawiera w sobie frazę, ale się od niej nie zaczyna)
               </span>
               <span>
                  <b>NO_MATCH</b> (indeks zupełnie nie zawiera w sobie frazy)
               </span>
               <span>
                  <b>MAIN_INDEX_WITHOUT_SPECIAL_CHARS</b> - identyczna informacja jak wyżej jednak zestawiająca indeks
                  bez znaków specjalnych vs FS
               </span>
               <span>
                  <b>MAIN_ALL_PRODUCT_INDEXES</b> - informacja czy FS znajduje się w polu allProductIndexes lub
                  searchIndexes - czyli czy mamy z dowolnego źródła mapowanie FS na ten produkt
               </span>
               <span>
                  <b>MAIN_EAN</b> - czy FS jest eanem produktu MAIN_OEM - czy FS jest oemem produktu bez znaków
                  specjalnych
               </span>
               <span>
                  <b>MAIN_TEC_NUM</b> - czy FS jest numerem tec doc produktu (rzadko mamy tę informację)
               </span>
               <span>
                  <b>MAIN_WHOLESALE_INDEX_MATCH_COUNT</b> - liczba hurtowni, które mają FS jako swój indeks bez znaków
                  specjalnych
               </span>
               <Typography variant="h6" color="primary">
                  Zamienniki:
               </Typography>
               <span>
                  <b>REPLACEMENTS_OEMS_MATCH_COUNT</b> - liczba wspólnych oemów między głównym produktem a zamiennikiem
               </span>
               <span>
                  <b>REPLACEMENTS_DEPTH</b> - poziom głębokości zamiennika, począwszy od 1
               </span>
               <Typography variant="h6" color="primary">
                  Zamienniki oraz wyszukiwanie po uuid (to, z którego korzysta Łukasz ze zgłoszeń):
               </Typography>
               <span>
                  <b>MAIN_PRODUCT</b> - true, jeśli produkt jest produktem głównym. Domyślnie dodaje to do wyniku 10000
                  żeby produkt był pierwszym wynikiem
               </span>
            </Box>
         </DialogContent>
         <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <ContainedButton variant="outlined" onClick={handleClose}>
               Zamknij
            </ContainedButton>
         </DialogActions>
      </Dialog>
   )
}
